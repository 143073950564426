<template>
    <div class="find_company">
        <div class="search_box" id="top">
            <div class="input_box">
                  <div class="input_shadow">
                     <el-input
                            type="text"
                            ref="input"
                            v-model="search.keywords"
                            @keyup.enter.native="handleSearch"
                            @input="closeDialog"
                            @focus="showHistory"
                            @blur="hiddenHistory"
                            placeholder="请输入网站名称 / 网址 、 公司名 、 行业名 、推广关键词等"
                            maxlength="100">
                        <template #append style="width: 48px">
                            <div class="box" @click="handleSearch">
                                <img src="@/assets/img/search/icon_search.png" alt="" width="18px" height="18px">
                            </div>
                        </template>
                     </el-input>
                      <div class="search_history" v-show="showInputHistory">
                          <div class="search_item" v-for="(item,i) in searchHistoryList" :key="i" @click="selectHistory(item)">{{item}}</div>
                      </div>
                      <div class="search_history" v-show="showInputSuggest">
                          <div class="search_item" v-for="(item,i) in searchSuggestList" :key="i" @click="selectHistory(item.company_name)">{{item.company_name}}</div>
                      </div>
                </div>

                <div class="empty_input" v-if="showEmptyInput">
                    <img src="@/assets/img/companyDetails/icon_exclamation.png" alt="">
                    <span>你还未输入关键词</span>
                </div>
            </div>
            <div class="s-l-line" style="margin-top: 22px">
                <span class="s-l-label">所在地区：</span>
                <div class="s-l-box">
                    <div class="cascader">
                        <el-popover
                                trigger="hover"
                                popper-class="cascader-popper"
                                placement="bottom-start"
                                v-model="areaVisible">
                            <el-cascader-panel
                                    :options="areaOptions"
                                    :props="props"
                                    v-model="search.s_area"
                                    @change="areaSelect">
                            </el-cascader-panel>
                            <span
                                    slot="reference"
                                    :class="{ 'cascader-span': area_name != '全部地区' }">
                            {{ area_name }}
                             <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        </el-popover>
                    </div>
                </div>
            </div>
            <div class="s-l-line">
                <span class="s-l-label">所属行业：</span>
                <div class="s-l-box">
                    <div class="cascader">
                        <el-popover
                            trigger="hover"
                            popper-class="cascader-popper"
                            placement="bottom-start"
                            v-model="visible">
                            <el-cascader-panel
                                :options="categoryOptions"
                                :props="props"
                                v-model="search.s_category"
                                @change="categorySelect">
                            </el-cascader-panel>
                            <span
                                slot="reference"
                                :class="{ 'cascader-span': category_name != '全部行业' }">{{ category_name }}
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                        </el-popover>
                    </div>
                </div>
            </div>
            <div class="s-l-line">
                <span class="s-l-label">查询范围：</span>
                <div class="s-l-box">
                    <div
                        class="checkbox"
                        :class="{checkboxed: search.s_scope.some((v, i) => v == el.value),}"
                        v-for="(el, index) in s_scope"
                        :key="index">
                        <i @click="scopeSelect(el.value)"></i>
                        <span @click="scopeSelect(el.value)">{{ el.label }}</span>
                    </div>
                </div>
            </div>
            <div class="s-l-line">
                <span class="s-l-label">推广平台：</span>
                <platformSelector ref="platformSelector" @childChange="platSelectSelect" :list="[1,2,3,4,5,6,7]"></platformSelector>
            </div>
            <div class="s-l-line">
                <span class="s-l-label">注册资本：</span>
                <div class="s-l-box">
                    <div
                        class="checkbox"
                        :class="{checkboxed:search.s_money.some((v, i) => v == el.value) && moneyStatus }"
                        v-for="(el, index) in s_money"
                        :key="index" >
                        <i @click="moneySelect(el.value)"></i>
                        <span @click="moneySelect(el.value)">{{ el.label }}</span>
                    </div>
                    <div
                        class="checkbox"
                        :class="{ checkboxed: !moneyStatus }">
                        <el-popover
                            trigger="click"
                            popper-class="cascader-popper"
                            placement="bottom-start"
                            v-model="moneyVisible">
                            <div class="myself">
                                <div class="m-list">
                                    <span class="m-list-txt">从</span>
                                    <div
                                        class="m-list-right"
                                        :class="{ 'm-list-right-error': moneyMinNotice }">
                                        <input
                                            class="m-list-right-input"
                                            v-model="moneyMin"
                                            type="number"
                                            oninput="if(value.length>8)value=value.slice(0,8)"/><strong class="m-list-right-strong">万</strong>
                                    </div>
                                </div>
                                <div class="m-list">
                                    <span class="m-list-txt">至</span>
                                    <div
                                        class="m-list-right"
                                        :class="{ 'm-list-right-error': moneyMaxNotice }">
                                        <input
                                            class="m-list-right-input"
                                            v-model="moneyMax"
                                            type="number"
                                            oninput="if(value.length>8)value=value.slice(0,8)"/><strong class="m-list-right-strong">万</strong>
                                    </div>
                                </div>
                                <div class="m-list">
                                    <a
                                        href="javascript:;"
                                        class="m-list-button"
                                        @click="myMoneySure">确定</a>
                                </div>
                            </div>
                            <span slot="reference" class="myself-hover"><i></i>自定义</span>
                        </el-popover>
                    </div>
                </div>

            </div>
            <div class="s-l-line" style="margin-bottom: 20px">
                <span class="s-l-label">成立年限：</span>
                <div class="s-l-box">
                    <div
                        class="checkbox"
                        :class="{checkboxed:search.s_age.some((v, i) => v == el.value) && ageStatus }"
                        v-for="(el, index) in s_age"
                        :key="index">
                        <i @click="ageSelect(el.value)"></i>
                        <span @click="ageSelect(el.value)">{{ el.label }}</span>
                    </div>
                    <div
                        class="checkbox"
                        :class="{ checkboxed: !ageStatus }">
                        <el-popover
                            trigger="click"
                            popper-class="cascader-popper"
                            placement="bottom-start"
                            v-model="ageVisible">
                            <div class="myself">
                                <div class="m-list">
                                    <span class="m-list-txt">从</span>
                                    <div
                                        class="m-list-right"
                                        :class="{ 'm-list-right-error': ageMinNotice }">
                                        <input
                                            class="m-list-right-input"
                                            v-model="ageMin"
                                            type="number"
                                            oninput="if(value.length>2)value=value.slice(0,2)"/><strong class="m-list-right-strong">年</strong>
                                    </div>
                                </div>
                                <div class="m-list">
                                    <span class="m-list-txt">至</span>
                                    <div
                                        class="m-list-right"
                                        :class="{ 'm-list-right-error': ageMaxNotice }">
                                        <input
                                            class="m-list-right-input"
                                            v-model="ageMax"
                                            type="number"
                                            oninput="if(value.length>2)value=value.slice(0,2)"/><strong class="m-list-right-strong">年</strong>
                                    </div>
                                </div>
                                <div class="m-list">
                                    <a
                                        href="javascript:;"
                                        class="m-list-button"
                                        @click="myAgeSure">确定</a>
                                </div>
                            </div>
                            <span slot="reference" class="myself-hover"><i></i>自定义</span>
                        </el-popover>
                    </div>
                </div>
            </div>
            <transition name="el-fade-in-linear">
                <div class="select-list" v-show="area_name_str || category_name_str || s_scope_name || s_platform_name || s_money_name || s_age_name">
                <span class="select-list-label">已选条件：</span>
                <div class="select-list-box">
                    <span v-if="area_name_str">所在地区：
                        {{
                            area_name_str.length > 20
                            ? area_name_str.substring(0, 20) + "..."
                            : area_name_str
                        }}
                        <font @click="search.s_area = [], area_name_str = '', area_name = '全部地区', init()"></font>
                    </span>

                    <span v-if="category_name_str">所属行业：
                        {{
                            category_name_str.length > 20
                            ? category_name_str.substring(0, 20) + "..."
                            : category_name_str
                        }}
                        <font @click="search.s_category = [], category_name_str = '', category_name = '全部行业', init()"></font>
                    </span>

                    <span v-if="s_scope_name">搜索范围：{{s_scope_name}}
                        <font @click=" search.s_scope = [], init() "></font>
                    </span>

                    <span v-if="s_platform_name">推广平台：{{s_platform_name}}
                        <font @click=" search.s_platform = [], $refs.platformSelector.clear(), init() "></font>
                    </span>

                    <span v-if="s_money_name">注册资本：{{s_money_name}}
                        <font @click="search.s_money = [], init(), moneyStatus = true"></font>
                    </span>

                    <span v-if="s_age_name">成立年限：{{s_age_name}}
                        <font  @click="search.s_age = [],  init(), ageStatus = true"></font>
                    </span>
                </div>
                <div class="select-list-clear" @click="clearAll">清除条件</div>
            </div>
            </transition>
        </div>

        <div class="list_box" v-loading="listLoading">
            <div class="list_head">
                <span class="result">共找到<span class="count">{{listData.real_cnt > 100000? '100000+':listData.real_cnt}}</span>个结果</span>
                <div class="header-btn-sort" :style="{ 'right': $store.state.userInfo.has_export_permission?'298px':''}">
                    <font class="header-btn-sort-name">{{ sort_name }}<i class="el-icon-arrow-down el-icon--right"></i></font>
                    <div class="header-btn-sort-dropdown">
                        <el-dropdown @command="sortSelect" placement="bottom-start">
                            <span class="el-dropdown-link">
                                下拉菜单<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    :command="el.value"
                                    v-for="(el, index) in sort"
                                    :key="index">{{ el.label }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <myButton v-if="$store.state.userInfo.has_export_permission" type="default" width="158" height="33" @click="exportTel" class="button"  style="right: 130px;">
                    <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                    <span slot="text">导出企业联系电话</span>
                </myButton>
                <myButton type="default" width="100" height="33" @click="exportList" class="button">
                    <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                    <span slot="text">立即导出</span>
                </myButton>
            </div>
            <div class="list_body" v-for="(item,i) in listData.rows" :key="i">
                <div class="list_item">
                    <div class="list-left">
                        <div class="list-left-txt" :style="{ background: item.logo_color }">
                            <font>{{ item.logo_img_str }}</font>
                        </div>
                    </div>
                    <div class="list_right">
                        <div class="company_line">
                            <div class="company_name" v-html="item.company_name" @click="$C.getCompanyDetails(item.company_name_digest)"></div>
                            <div :class="item.company_status=='在业' || '续存'?'running':'close_down'">{{item.company_status}}</div>
                        </div>
                        <div class="info">
                            <span class="title">法定代表人：</span>
                            <span class="container">{{item.legal_person}}</span>
                            <span class="title">成立日期：</span>
                            <span class="container">{{item.establish_date}}</span>
                            <span class="title">注册资本：</span>
                            <span class="container">{{item.capital}}</span>
                        </div>
                        <div class="address">
                            <span class="title">地址：</span>
                            <span class="container">{{item.company_address}}</span>
                        </div>
                        <div class="extend">
                            <img src="@/assets/img/search/icon_qd.png" alt="">
                            <span class="title">推广平台：</span>
                            <span class="container">{{item.nAdPlatform.length + '个' || '--'}}</span>
                            <img src="@/assets/img/search/icon_count.png" alt="">
                            <span class="title">推广关键词：</span>
                            <span class="container">{{item.nAdWordCnt}}个</span>
                            <img src="@/assets/img/search/history.png" alt="">
                            <span class="title">推广记录：</span>
                            <span class="container">{{item.nAdRecordCnt}}条</span>
                            <img src="@/assets/img/search/icon_date.png" alt="">
                            <span class="title">最后推广时间：</span>
                            <span class="container">{{item.nAdLatestTime}}</span>
                        </div>
                        <div class="gray_box" v-if="item.highlight.length > 0">
                            <span class="title"> <span v-html="item.highlight[0].k"></span>：</span>
                            <span class="container" v-html="item.highlight[0].v[0]"></span>
                        </div>
                        <my-button type="primary" width="90" height="35" class="button" @click="$C.getCompanyDetails(item.company_name_digest)">
                            <span slot="text">进入主页</span>
                        </my-button>
                        <my-button class="button_next" v-if="!item.monitor" @click="handelMonitor(item.company_name_digest, 1)" type="default" width="90" height="33">
                            <span slot="text">监测推广</span>
                        </my-button>
                        <el-dropdown class="button_next" v-else>
                            <my-button type="secondary" width="90" height="33" style="background-color: #b5c2d2">
                                <img slot="img" src="@/assets/img/companyDetails/supervise.png" alt="" width="14px" height="14px">
                                <span slot="text" style="color: #FFFFFF">监测中</span>
                            </my-button>
                            <el-dropdown-menu slot="dropdown" >
                                <el-dropdown-item>
                                    <div class="line" @click="$router.push('/box/dynamic')">查看监测动态</div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div class="line" @click="cancelMonitor(item.company_name_digest, 1)">取消监测</div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
            <div class="list_loading" v-if="!listData.rows.length">
                <img v-if="!listLoading" class="empty_img" src="@/assets/img/search/empty_list.png" alt="">
            </div>
            <div class="list_footer" v-if="listData.rows.length">
                <page :config="listConfig"  @pageChange="pageChange" @limitChange="limitChange"></page>
            </div>
        </div>

        <export_dialog
            @beforeClose="dialogVisible = false"
            @handleExport="handleExport"
            :visible.sync="dialogVisible"
            :all-cnt="parseInt(exportCnt)"
            :surplus="surplus"
            :fast-limit="2500"
            :total-limit="10000"
            width="60%">
        </export_dialog>

        <el-dialog
            custom-class="telephone_dialog"
            title="导出数据"
            :visible.sync="telDialog">
            <div class="line1">
                <span>数据量：</span>
                <span style="color: #1f81f8">{{exportCntTel}}</span>条
            </div>
            <div class="time">
                <span>今日还可以免费导出<span style="color: #ff8840;">{{surplusTel}}</span>次</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <my-button @click="telDialog = false" type="secondary" width="80" height="33" style="margin-right: 11px">
                    <span slot="text">取消</span>
                </my-button>
                <my-button @click="handelExportTel" type="primary" width="80" height="33">
                    <span slot="text">确定</span>
                </my-button>
            </span>
        </el-dialog>

        <common_dialog
            @beforeClose="notVipShow = false"
            :visible.sync="notVipShow"
            :text="commonDialogText"
            width="40%">
        </common_dialog>
    </div>
</template>

<script>
import searchOption from "./searchOption";
import Bus from "@/utils/bus";
import page from "../../../components/page";
import platformSelector from "@/components/platformSelector/platformSelector";
import MyButton from "@/components/myButton/myButton";
import * as api from "@/api/search"
import common_dialog from "../../../components/dialogs/common_dialog";
import export_dialog from "../../../components/dialogs/export_dialog";
import axios from 'axios'
import PinganAna from "pingansec-vue-ana";
  export default {
    name: "findCompetitive",
    components: {
      MyButton,
      platformSelector,
      page,
      common_dialog,
      export_dialog
    },
    mixins: [searchOption],
    data() {
      return {
        showInputSuggest: false,
        searchSuggestList: [],
        showEmptyInput: false,
        showInputHistory: false,
        searchHistoryList: [],
        timer: null,
        areaVisible: false,
        visible: false,
        moneyVisible: false,
        ageVisible: false,
        props: { multiple: true,  expandTrigger: 'hover'},
        area_name_str: "",
        area_name: "全部地区",
        category_name_str: "",
        category_name: "全部行业",
        // 自定义的注册资本
        moneyMin: 0,
        moneyMax: 100,
        moneyStatus: true,
        moneyMinNotice: false,
        moneyMaxNotice: false,
        // 自定义的成立年限
        ageMin: 0,
        ageMax: 10,
        ageStatus: true,
        ageMinNotice: false,
        ageMaxNotice: false,
        search: {
          keywords: "",
          // 搜索范围
          s_scope: [],
          // 所在地区
          s_area: [],
          // 推广平台
          s_platform: [],
          //所属行业
          s_category: [],
          // 注册资本
          s_money: [],
          // 成立年限
          s_age: [],
          // 排序
          sort: 1,
          page: 1,
          limit: 10,
        },
        listData: {
          cnt: 0,
          rows:[]
        },
        listConfig: {
          page: null,
          limit: null,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5,
          real_cnt: null
        },
        listLoading: false,
        sort_name: "默认排序",
        sort: [
          {
            label: "默认排序",
            value: 1,
          },
          {
            label: "最后推广时间从早到晚",
            value: 2,
          },
          {
            label: "最后推广时间从晚到早",
            value: 3,
          },
          {
            label: "推广记录数量从高到低",
            value: 4,
          },
          {
            label: "推广记录数量从低到高",
            value: 5,
          },
          {
            label: "推广关键词数量从高到低",
            value: 6,
          },
          {
            label: "推广关键词数量从低到高",
            value: 7,
          },
        ],
        notVipShow: false, //非vip导出提示弹窗
        dialogVisible: false, //导出列表确认弹窗
        telDialog: false,
        surplus: null, //剩余导出次数
        surplusTel: null, //剩余导出次数
        exportCnt: null, //导出数量
        exportCntTel: null, //导出数量
        lastRequest: '',
        commonDialogText: ''
      }
    },
    computed: {
      // 注册资本
      s_money_name() {
        let string = '';
        for (var i = 0; i < this.search.s_money.length; i++) {
          let arr = this.search.s_money[i];
          let add = '';
          if (arr[0] && arr[1]) {
           add =  arr[0] + "~" + arr[1] + "万";
          } else if (!arr[0] && arr[1]) {
            add =  arr[1] + "万以内";
          } else if (arr[0] && !arr[1]) {
            add =  arr[0] + "万以上";
          } else {
            add = ''
          }
          string.length > 0 ?string = string + '/' + add : string += add
        }
        return string
      },
      // 注册年限
      s_age_name() {
        let string = '';
        for (var i = 0; i < this.search.s_age.length; i++) {
          let arr = this.search.s_age[i];
          let add = '';
          if (arr[0] && arr[1]) {
            add =  arr[0] + "~" + arr[1] + "年";
          } else if (!arr[0] && arr[1]) {
            add = arr[1] + "年以内";
          } else if (arr[0] && !arr[1]) {
            add =  arr[0] + "年以上";
          } else {
            add = ''
          }
          string.length > 0 ?string = string + '/' + add : string += add
        }
        return string
      },
      // 查询范围
      s_scope_name() {
        let arr = this.search.s_scope;
        let txt = [];
        this.s_scope.map((v) => {
          if (arr.includes(v.value)) {
            txt.push(v.label);
          }
        });
        return txt.length > 0 ? txt.join("/") : false;
      },
      //推广平台
      s_platform_name() {
        let arr = this.search.s_platform;
        let txt = [];
        this.s_platform.map((v) => {
          if (arr.includes(v.value)) {
            txt.push(v.label);
          }
        });
        return txt.length > 0 ? txt.join("/") : false;
      }
    },
    created() {
      PinganAna.fire(3142)
      if(this.$route.query.word) {
        this.search.keywords = this.$route.query.word
      }
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
      clearTimeout(this.timer)
    },
    methods: {
      handelMonitor(keyword,type) {
        let params = {
          keyword,
          type
        }
        api.add_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '监测成功！还可监测' +res.data.data.monitor_company_last +
                '家竞品公司，'+res.data.data.monitor_word_last+'个关键词'
            })
            this.init()
          } else {
            if(res.data.result_code == 120011 && (this.$store.state.userInfo.vip_type == 20 || this.$store.state.userInfo.vip_type == 10)) {
              this.commonDialogText = '您的监测数量已达上限，至尊版可监测50家竞品/100个关键词，如需监测更多，请联系您的客户经理开通'
              this.notVipShow = true;
            } else if(res.data.result_code == 120011 && this.$store.state.userInfo.vip_type == 5) {
              this.commonDialogText = '监测已达上限。标准版可监测竞品公司10家/关键词20个，请联系在线客服开通'
              this.notVipShow = true;
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          }
        })
      },
      cancelMonitor(keyword,type) {
        let params = {
          keyword,
          type
        }
        api.cancel_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '已取消监测！'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      exportTel() {
        api.get_last_export_cnt().then(res => {
          if(res.data.result_code==0) {
            this.surplusTel = res.data.data.total-res.data.data.used;
            this.exportCntTel = this.listConfig.real_cnt > 1000?1000:this.listConfig.real_cnt
            this.telDialog = true
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      handelExportTel() {
        let params = {
          export_type: '12',
          export_limit: this.exportCntTel,
          export_condition: JSON.stringify(this.search)
        }
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      exportList() {
        PinganAna.fire(3157)
        if (this.$store.state.userInfo.vip_type == 10) {
          this.notVipShow = true;
          this.commonDialogText = '您当前为试用版会员，标准版会员可导出数据，请联系您的客户经理开通'
        } else if(this.$store.state.userInfo.vip_type == 5) {
          this.notVipShow = true;
          this.commonDialogText = '当前为基础版会员，标准版会员可导出数据，请联系在线客服开通';
        } else {
          api.get_last_export_cnt().then(res => {
            if(res.data.result_code==0) {
              this.surplus = res.data.data.total-res.data.data.used;
              this.exportCnt = this.listConfig.real_cnt
              this.dialogVisible = true
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      handleExport(count) {
        let params = {
          export_type: '4',
          export_limit: count,
          export_condition: JSON.stringify(this.search)
        }
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.dialogVisible = false
            PinganAna.fire(3158)
            PinganAna.ready(() => {
              PinganAna.userClickLog(
                '数据导出',
                '{"exportType":"找竞品-任意搜索公司","exportTitle":"'+this.search.keywords+'","size":"'+count+'"}',
                '【AI竞投】系统',
                this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                '--',
                this.$store.state.userInfo.account
              )
            })
            const { href } = this.$router.resolve({
              name: "export",
            });
             setTimeout(() => {
               window.open(href, '_blank');
             },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      handleSearch() {
        if(!this.search.keywords) {
          this.showEmptyInput = true;
          this.time = setTimeout(() => {
            this.showEmptyInput = false;
          }, 3000)
          return
        }
        this.searchHistoryList.unshift(this.search.keywords)
        localStorage.setItem('search_history', JSON.stringify(this.searchHistoryList))
        this.showInputHistory = false
        this.clearAll()
      },
      closeDialog(word) {
        if(word) {
          this.showEmptyInput = false;
          this.showInputHistory = false;
          this.showInputSuggest = true ;
          let params = {
            keyword: this.search.keywords,
            type: 1
          };
          api.get_ad_compete_list_search(params).then(res => {
            if(res.data.result_code == 0) {
              this.searchSuggestList = res.data.data;
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      showHistory() {
        if(!this.search.keywords) {
          this.searchHistoryList = Array.from(new Set(JSON.parse(localStorage.getItem('search_history')))).slice(0,5)
          this.showInputHistory = true
        }
      },
      hiddenHistory() {
        setTimeout(() => {
          this.showInputHistory = false
          this.showInputSuggest = false
        },500)
      },
      selectHistory(word) {
        this.search.keywords = word;
        this.showInputHistory = false
        this.showInputSuggest = false
        this.handleSearch();
      },
      sortSelect(v) {
        this.search.sort = v;
        this.init();
      },
      pageChange(val) {
        this.search.page = val
        this.init()
        document.getElementById("top").scrollIntoView()

      },
      limitChange(val) {
        this.search.page = 1;
        this.search.limit = val;
        this.init()
        document.getElementById("top").scrollIntoView()
      },
      init() {
        if(this.lastRequest) {
          this.lastRequest.cancel()
        }
        const CancelToken = axios.CancelToken;
        this.lastRequest = CancelToken.source();
        this.sort_name = this.sort[this.search.sort - 1].label;
        this.listLoading = true
        let params = {
          ...this.search,
          ...this.lastRequest.token
        }
        api.get_company_list(params).then(res => {
          if(res.data.result_code == 0) {
            if(this.$store.state.userInfo.vip_type == 5 && res.data.data.limit_search_bool) {
              this.notVipShow = true;
              this.commonDialogText = '基础版每天可免费搜索3次。标准版无搜索限制，请联系在线客服开通';
            }
            PinganAna.fire(3156)
            if(this.search.keywords) {
              PinganAna.fire(3155)
              PinganAna.ready(() => {
                PinganAna.userClickLog(
                  '"Position":"搜索"',
                  '{"searchType":"查推广公司","pageName":"'+this.$route.name+'",'+JSON.stringify(params)+'}',
                  '【AI竞投】系统',
                  this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                  '--',
                  this.$store.state.userInfo.account
                )
              })
            }
            this.listData = res.data.data
            this.listLoading = false
            this.listConfig.total = this.listData.cnt
            this.listConfig.real_cnt = this.listData.real_cnt
            this.$nextTick(() => {
              this.listConfig.page = this.search.page
              this.listConfig.limit = this.search.limit
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
            this.listLoading = false
          }
        })
      },
      getCompanyDetails(digest) {
        PinganAna.fire(3159)
        const { href } = this.$router.resolve({
          name: "companyDetails",
          query: {
            digest
          }
        });
        window.open(href, '_blank');
      }
    }
  }
</script>

<style scoped lang="scss">
.find_company {
    .search_box {
        width: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        box-sizing: border-box;
        margin-top: 59px;
        .input_box {
            display: flex;
            align-items: center;
            height: 80px;
            border-bottom: solid 1px #ebeff2;
            padding-left: 23px;
            position: relative;
            .input_shadow {
                width: 713px;
                height: 48px;
                background-color: #f0f5f9;
                border-radius: 6px;
                .el-input {
                    width: 705px;
                    height: 40px;
                    background-color: #ffffff;
                    border-radius: 6px;
                    margin: 4px;
                }
                .search_history {
                    width: 661px;
                    background-color: #ffffff;
                    box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.3);
                    border-radius: 6px;
                    border: solid 1px #ebeff2;
                    position: absolute;
                    left: 25px;
                    top: 60px;
                    max-height: 174px;
                    overflow-y: auto;
                    z-index: 10;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    .search_item {
                        height: 32px;
                        font-size: 14px;
                        color: #333333;
                        padding-left: 15px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        &:hover {
                            background-color: #e8f2fe;
                            color: #1f81f8;
                        }
                    }
                }
            }
            .empty_input {
                width: 186px;
                height: 44px;
                background-color: #fffaf7;
                box-shadow: 0 4px 10px 0 rgba(204, 204, 204, 0.3);
                border-radius: 6px;
                border: solid 1px #ffd1b5;
                display: flex;
                align-items: center;
                position: absolute;
                top: 60px;
                left: 27px;
                img {
                    height: 14px;
                    width: 14px;
                    margin-left: 16px;
                    margin-right: 10px;
                }
                span {
                    font-size: 14px;
                    color: #333333;
                }
            }
        }
        .cascader-span {
            color: $c1f81f8;
        }
        .s-l-line {
            margin-left: 19px;
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            .s-l-label {
                font-size: 14px;
                color: #999999;
            }
            .s-l-box {
                display: inline-flex;
                font-size: 14px;
                color: #333333;
                cursor: pointer;
                .checkbox {
                    width: 122px;
                    display: flex;
                    align-items: center;
                    i {
                        width: 14px;
                        height: 14px;
                        background: url('../../../assets/img/search/checkbox.png') center center no-repeat;
                        background-size: 14px;
                        margin-right: 6px;
                    }
                    .myself-hover {
                        display: flex;
                        align-items: center;
                    }
                }
                .checkboxed {
                    i {
                        background: url('../../../assets/img/search/checkboxed.png') center center no-repeat;
                        background-size: 14px;
                    }
                }
            }

        }
        .select-list {
            width: 100%;
            border-top: 1px solid #e9e9e9;
            display: flex;
            padding: 12px 0 12px 20px;
            box-sizing: border-box;
            min-height: 61px;
            .select-list-label {
                @extend .font-normal;
                font-size: 14px;
                color: #999999;
                line-height: 26px;
            }

            .select-list-box {
                margin-left: 10px;
                flex: 1;
                display: flex;
                flex-wrap: wrap;

                span {
                    background: rgba(31, 129, 248, 0.1);
                    padding: 6px 10px;
                    @extend .font-normal;
                    font-size: 14px;
                    color: #1f81f8;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;

                    font {
                        display: inline-block;
                        width: 8px;
                        height: 7px;
                        background: url('../../../assets/img/search/close.png') center center no-repeat;
                        background-size: 8px 7px;
                        margin-left: 8px;
                        cursor: pointer;
                    }
                }
            }

            .select-list-clear {
                @extend .font-normal;
                font-size: 14px;
                color: #999999;
                margin-right: 18px;
                background: url('../../../assets/img/search/delete.png') left 6px no-repeat;
                background-size: 14px;
                text-indent: 20px;
                line-height: 26px;
                width: 80px;
                cursor: pointer;
            }
        }
    }
    .list_box {
        width: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        box-sizing: border-box;
        margin-top: 10px;
        .list_head {
            height: 50px;
            border-bottom: 1px solid #ebeff2;
            display: flex;
            align-items: center;
            position: relative;
            .result {
                font-size: 14px;
                color: #999999;
                margin-left: 18px;
            }
            .count {
                margin: 0;
                color: #ef3819;
            }
            .header-btn-sort {
                margin-left: 10px;
                display: inline-flex;
                height: 33px;
                border-radius: 6px;
                border: solid 1px #e4e4e4;
                background: #ffffff;
                padding: 0 10px 0 16px;
                align-items: center;
                cursor: pointer;
                position: absolute;
                right: 135px;
                top: 8px;

                font {
                    @extend .font-normal;
                    font-size: 14px;
                    color: #333333;
                }

                .header-btn-sort-dropdown {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                }
            }
            .button {
                position: absolute;
                right: 20px;
            }
        }
        .list_loading {
            width: 100%;
            height: 215px;
            text-align: center;
            .empty_img {
                width: auto;
                height: 215px;
            }
        }
        .list_body {
            width: 100%;
            padding-bottom: 28px;
            border-bottom: 1px solid #ebeff2;
            &:hover {
                background-color: #e8f2fe;
                .company_name{
                    color: #1f81f8 !important;
                }
            }
            .list_item {
                width: 100%;
                display: inline-flex;
                .list-left {
                    display: inline-block;
                    .list-left-txt {
                        @extend .font-normal;
                        width: 75px;
                        height: 75px;
                        background-color: #3aa678;
                        opacity: .6;
                        border-radius: 3px;
                        font-size: 20px;
                        letter-spacing: 2px;
                        color: #fff;
                        padding: 14px;
                        box-sizing: border-box;
                        margin-left: 25px;
                        margin-top: 25px;
                        margin-right: 22px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        font {
                            text-align: center;
                        }
                    }
                }
                .list_right {
                    position: relative;
                    width: 100%;
                    .company_line {
                        display: inline-flex;
                        align-items: center;
                        margin-top: 25px;
                        .company_name {
                            cursor: pointer;
                            font-size: 20px;
                            color: #333333;
                        }
                        .running {
                            display: inline-block;
                            font-size: 12px;
                            font-weight: normal;
                            color: #1f81f8;
                            padding: 3px 6px 4px 6px;
                            margin-left: 12px;
                            border-radius: 5px;
                            background-color:  rgb(31,129,248,0.1);
                        }
                        .close_down {
                            display: inline-block;
                            font-size: 12px;
                            font-weight: normal;
                            color: #ef3819;
                            padding: 3px 6px 4px 6px;
                            margin-left: 12px;
                            border-radius: 5px;
                            background-color:  rgb(239,56,25,0.1);
                        }
                    }
                    .info {
                        font-size: 14px;
                        margin-top: 21px;
                    }
                    .address {
                        font-size: 14px;
                        margin-top: 12px;
                        max-width: 600px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .extend {
                        font-size: 14px;
                        margin-top: 12px;
                        img  {
                            width: 12px;
                            height: 12px;
                            margin-right: 5px;
                        }
                    }
                    .gray_box {
                        font-size: 14px;
                        margin-top: 20px;
                        height: 35px;
                        width: calc(100% - 40px);
                        background-color: #f8fafc;
                        border-radius: 5px;
                        padding-left: 15px;
                        line-height: 35px;
                    }
                    .button {
                        position: absolute;
                        right: 25px;
                        top: 42px;
                    }
                    .button_next {
                        position: absolute;
                        right: 25px;
                        top: 85px;
                    }
                    .title {
                        color: #999999;
                    }
                    .container {
                        color: #666666;
                        margin-right: 45px;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.find_company .telephone_dialog {
    width: 435px;
    height: 188px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 1px #ebeff2;
    .el-dialog__header {
        padding: 20px;
        font-size: 16px;
        color: #333333;
    }
    .el-dialog__body {
        padding: 27px 20px;
        padding-top: 12px;
        font-size: 14px;
        .line1 {
            color: #333;
            margin-bottom: 10px;
        }
        .time {
            color: #999;
        }
    }
    .el-dialog__footer {
        padding-top: 0;
    }
}
.find_company .el-input-group__append, .el-input-group__prepend  {
    padding: 0;
    background-color: #ffffff;
    cursor: pointer;
    &:hover {
        background-color: #e9f3fd;
    }
    &:active {
        background-color: #f6f9fe;
    }
    .box {
        width: 46px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.find_company .list_box .el-loading-spinner {
    top: 150px;
}
.find_company .list_box .list_body .list_item .list_right .company_name em{
    color: #ef3819;
}
.find_company .list_box .list_body .list_item .list_right .gray_box em{
    color: #ef3819;
}
.myself {
    padding: 15px;
    .m-list {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;

        .m-list-txt {
            color: #999999;
            margin-right: 10px;
        }
        .m-list-right {
            width: 100px;
            height: 32px;
            background-color: #ffffff;
            border-radius: 3px;
            border: solid 1px #dcdcdc;
            display: flex;
            align-items: center;
            justify-content: center;
            .m-list-right-input {
                flex: 1;
                width: 50px;
                border: none;
                outline: none;
                background: none;
                margin-left: 10px;
                margin-right: 10px;
                color: #333333;
            }
            .m-list-right-strong {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #999999;
                line-height: 32px;
                margin-right: 10px;
            }
        }
        .m-list-right-info {
            border-color: #1f81f8;
        }
        .m-list-right-error {
            border-color: #ff5f57;
        }
        .m-list-button {
            color: #1f81f8;
            width: 70px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background-color: #ffffff;
            border-radius: 3px;
            border: solid 1px #1f81f8;
        }
    }
    .m-list:last-child {
        margin-bottom: 0;
        justify-content: flex-end;
    }
}
/* chrome */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* 火狐浏览器 */
input[type="number"]{
    -moz-appearance: textfield;
}

.header-btn-sort-dropdown .el-dropdown{
    width: 100%!important;
    height: 100%!important;
}

.header-btn-sort-dropdown .el-dropdown .el-dropdown-link{
    width: 100%!important;
    height: 100%!important;
    display: block!important;
}
.el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden;
}
</style>
